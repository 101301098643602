import API from './api'
import { axiosWithErrorHandler } from './axios'

 export const GET_TOURNAMENTS = async(page: number, limit: number) => {
    const response = await axiosWithErrorHandler(`GET`, `${API.API_URL}${API.TOURNAMENT_LIST}?page=${page}&pageSize=${limit}`)
    return response.data.data
}

export const GET_TOURNAMENT_BY_ID = async (tournamentId: string) => {
    const response = await axiosWithErrorHandler(`GET`, `${API.API_URL}${API.TOURNAMENT_INFO}/${tournamentId}`)
    return response.data.data;
};

export const CREATE_TOURNAMENT = async (state: any) => {
    const response = await axiosWithErrorHandler(`POST`, `${API.API_URL}${API.TOURNAMENT_INFO}`, state)
    return response.data;
};

export const UPDATE_TOURNAMENT = async (state: any) => {
    const response = await axiosWithErrorHandler(`PUT`, `${API.API_URL}${API.TOURNAMENT_INFO}`, state)
    return response.data;
};

export const DELETE_TOURNAMENT = async (state: any) => {
    const response = await axiosWithErrorHandler(`POST`, `${API.API_URL}${API.TOURNAMENT_DELETE}`, state)
    return response.data;
};
