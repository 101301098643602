




























































































































import { Component, Mixins } from "vue-property-decorator";
import AgentListComponent from "./TournamentList.component";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faPlus } from "@fortawesome/free-solid-svg-icons";

library.add(faPlus);

@Component
export default class AgentList extends Mixins(AgentListComponent) { }
