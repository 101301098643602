import { Component, Vue } from "vue-property-decorator";
import { VuejsDatatableFactory } from "vuejs-datatable";
import moment from 'moment';
import Model from "@/models/Model";
import VModal from 'vue-js-modal'
import vSelect from 'vue-select';
import { DELETE_TOURNAMENT, GET_TOURNAMENTS, UPDATE_TOURNAMENT } from "../../../../api/tournament"

Vue.use(VuejsDatatableFactory);
Vue.use(VModal, { componentName: 'modal' })
@Component({
    components: {
        vSelect
    },
    filters: {
        currency(value: any) {
            const val = (value / 1).toFixed(2).replace('.', '.')
            return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        },
        dateTime(val: any) {
            return val ? moment(val).local().format('DD-MM-YYYY HH:mm:ss') : ''
        },
        unixToDateString(val: any){
            return val ? moment.unix(val).format('DD-MM-YYYY HH:mm:ss') : ''
        }
    }
})
default class AgentList extends Vue {
    public tournaments: any = []
    private model: Model;

    private paginate = {
        page: 1,
        limit: 100
    }

    public pageSize = 0;
    public paginateShow = false;
    public dataLoading = false;
   
    

    private BlockUIStyle = {
        color: '#CFA137',
        loader: 'spinner',
        width: 145,
        height: 235,
        backgroundColor: '#000000',
        opacity: 0.5,
    }


    constructor() {
        super();
        this.model = new Model();
    }


    public activeOption: any[] = [ { label:'Active', value: true}, {label:'unActive', value: false}]

    async mounted() {
        this.dataLoading = true
        await this.getTournaments(this.paginate.page, this.paginate.limit)
        this.dataLoading = false
    }

    public async changeActive(id: string): Promise<void> { 
        
        this.$swal({
            title: this.$t('MANAGEMENT.WANT_EDIT_DATA').toString(),
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#CFA137',
            confirmButtonText: `<span style='color: white;'>${this.$t('CONFIRM').toString()}</span>`,
            cancelButtonColor: '#FF0000',
            cancelButtonText: `<span style='color: white;'>${this.$t('CANCEL').toString()}</span>`,
        }).then(async (result) => {

            const index = this.tournaments.metadata.findIndex( (x: any) => x.id == id)
            const isFoundIndex = index > -1
            const active = this.tournaments.metadata[index].metadata.data.active

            if (!result.isConfirmed) {
                if(isFoundIndex) this.tournaments.metadata[index].metadata.data.active = !active
                return
            }
            
            const loader = this.$loading.show(this.BlockUIStyle);
            const response = await UPDATE_TOURNAMENT({tournamentId: id, active})
            loader.hide()
            
            if (!response.success) {
                if(isFoundIndex) this.tournaments.metadata[index].metadata.data.active = !active
                this.$swal({
                    text: response.error.message,
                    icon: 'error',
                    confirmButtonColor: '#CFA137',
                    confirmButtonText: `<span style='color: white;'>${this.$t('OK').toString()}</span>`
                });
                return
            } 
            
        })
    }

    public async deleteTournament(tournamentID: string): Promise<void>{
        this.$swal({
            title: this.$t('MANAGEMENT.WANT_EDIT_DATA').toString(),
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#CFA137',
            confirmButtonText: `<span style='color: white;'>${this.$t('CONFIRM').toString()}</span>`,
            cancelButtonColor: '#FF0000',
            cancelButtonText: `<span style='color: white;'>${this.$t('CANCEL').toString()}</span>`,
        }).then(async (result) => {

            if (!result.isConfirmed) return
            
            const loader = this.$loading.show(this.BlockUIStyle);
            const responseDeleteTournament = await DELETE_TOURNAMENT({'tournament_id': tournamentID})
            loader.hide()
            
            if (!responseDeleteTournament.success) {
                this.$swal({
                    text: responseDeleteTournament.error.message,
                    icon: "error",
                    confirmButtonColor: '#CFA137',
                    confirmButtonText: `<span style='color: white;'>${this.$t('OK').toString()}</span>`
                });
                return
            } 

            if(responseDeleteTournament.success){
                this.$swal({
                    text: `Success`,
                    icon: "success",
                    timer: 3000,
                    showConfirmButton: false,
                    timerProgressBar: true,
                });
            }
            
            await this.getTournaments(this.paginate.page, this.paginate.limit)

        })
       
    }

    

    private async getTournaments(page: number, limit: number): Promise<void> {

        const loader = this.$loading.show(this.BlockUIStyle);

        const tourResponse = await GET_TOURNAMENTS(page, limit)
        this.tournaments = tourResponse[0]
        
        const hasMetadata = this.tournaments.metadata.length >= 1
        if ( hasMetadata) {
            const num = this.paginate.page * this.paginate.limit
            for (let i = 0, k = num; i < this.tournaments.metadata.length; i++, k++) {
                this.tournaments.metadata[i].no = k - this.paginate.limit
            }

            const isCountGreaterThanPageLimit = this.tournaments.pageInfo[0].count > this.paginate.limit
            if (isCountGreaterThanPageLimit) {
                this.paginateShow = true
            }
            this.pageSize = this.tournaments.pageInfo[0].size;

        } else {
            this.paginateShow = false
        }

        loader.hide()
    }


    public async clickCallback(number: number): Promise<void> {
        this.paginate.page = number
        await this.getTournaments(this.paginate.page, this.paginate.limit)
    }

}

export default AgentList