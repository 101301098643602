var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("main", [
    _c("nav", { attrs: { "aria-label": "breadcrumb" } }, [
      _c("ol", { staticClass: "breadcrumb" }, [
        _c(
          "li",
          { staticClass: "breadcrumb-item" },
          [
            _c(
              "router-link",
              { attrs: { id: "'breadcrumb0", to: { name: "TournamentList" } } },
              [
                _c("span", { attrs: { to: "" } }, [
                  _vm._v(
                    _vm._s(_vm.$t("TOURNAMENT.NAV_TOURNAMENT_MANAGEMENT")) + " "
                  )
                ])
              ]
            )
          ],
          1
        ),
        _c("li", { staticClass: "breadcrumb-item" }, [
          _c("span", { attrs: { to: "" } }, [
            _vm._v(" " + _vm._s(_vm.$t("TOURNAMENT.NAV_TOURNAMENT")) + " ")
          ])
        ])
      ])
    ]),
    _c("div", [
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-6 d-flex justify-content-start" }, [
          _c("h1", {}, [
            _vm._v(" " + _vm._s(_vm.$t("TOURNAMENT.H1_TOURNAMENT")) + " ")
          ])
        ]),
        _c(
          "div",
          { staticClass: "col-6 d-flex justify-content-end" },
          [
            _c(
              "router-link",
              { attrs: { to: { name: "AddTournament", query: {} } } },
              [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-yellow my-2",
                    attrs: { id: "createTournament", type: "button" }
                  },
                  [
                    _vm._v(
                      " " +
                        _vm._s(_vm.$t("TOURNAMENT.CREATE_TIER_TOURNAMENT")) +
                        " "
                    )
                  ]
                )
              ]
            )
          ],
          1
        )
      ]),
      !_vm.dataLoading
        ? _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-12" }, [
              _c("div", { staticClass: "row mt-2" }, [
                _c(
                  "div",
                  { staticClass: "col-sm-12" },
                  [
                    _vm.paginateShow
                      ? _c("paginate", {
                          attrs: {
                            "page-count": _vm.pageSize,
                            "page-range": 3,
                            "prev-text": "<<",
                            "next-text": ">>",
                            "click-handler": _vm.clickCallback,
                            "container-class": "pagination float-right",
                            "prev-class": "inline",
                            "next-class": "inline",
                            "page-class": "inline"
                          }
                        })
                      : _vm._e()
                  ],
                  1
                )
              ]),
              _c("div", { staticClass: "table-responsive" }, [
                _c(
                  "table",
                  { staticClass: "table table-bordered table-hover" },
                  [
                    _c("thead", [
                      _c("tr", [
                        _c("th", [_vm._v(_vm._s(_vm.$t("MANAGEMENT.NO")))]),
                        _c("th", [
                          _vm._v(_vm._s(_vm.$t("TOURNAMENT.TIER_NAME")))
                        ]),
                        _c("th", [
                          _vm._v(" " + _vm._s(_vm.$t("TOURNAMENT.BUYIN")) + " ")
                        ]),
                        _c("th", [
                          _vm._v(
                            " " + _vm._s(_vm.$t("TOURNAMENT.SCHEDULE")) + " "
                          )
                        ]),
                        _c("th", [
                          _vm._v(" " + _vm._s(_vm.$t("TOURNAMENT.LAST_RUN")))
                        ]),
                        _c("th", [
                          _vm._v(" " + _vm._s(_vm.$t("TOURNAMENT.NEXT_RUN")))
                        ]),
                        _c("th", [_vm._v(_vm._s(_vm.$t("TOURNAMENT.ACTIVE")))]),
                        _c("th", [_vm._v(_vm._s(_vm.$t("TOURNAMENT.MANAGE")))])
                      ])
                    ]),
                    _vm.dataLoading
                      ? _c("tbody", [_vm._m(0)])
                      : _c(
                          "tbody",
                          [
                            _vm.tournaments.length == 0
                              ? _c("tr", [
                                  _c("td", { attrs: { colspan: "11" } }, [
                                    _vm._v(_vm._s(_vm.$t("DATA_NOT_FOUND")))
                                  ])
                                ])
                              : _vm._l(_vm.tournaments.metadata, function(r) {
                                  return _c("tr", { key: r.id }, [
                                    _c("td", [_vm._v(_vm._s(r.no + 1))]),
                                    _c("td", [
                                      _c("span", [
                                        _vm._v(" " + _vm._s(r.title))
                                      ])
                                    ]),
                                    _c("td", [
                                      _c("span", [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm._f("currency")(r.buyin)
                                            ) +
                                            " "
                                        )
                                      ])
                                    ]),
                                    _c("td", [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            r.metadata.data.resetSchedule
                                          ) +
                                          " "
                                      )
                                    ]),
                                    _c("td", [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm._f("unixToDateString")(
                                              r.prev_reset
                                            )
                                          )
                                      )
                                    ]),
                                    _c("td", [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm._f("unixToDateString")(
                                              r.next_reset
                                            )
                                          )
                                      )
                                    ]),
                                    _c("td", [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "custom-control custom-switch"
                                        },
                                        [
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value: r.metadata.data.active,
                                                expression:
                                                  "r.metadata.data.active"
                                              }
                                            ],
                                            staticClass: "custom-control-input",
                                            attrs: {
                                              type: "checkbox",
                                              id: "customSwitch" + r.id
                                            },
                                            domProps: {
                                              checked: Array.isArray(
                                                r.metadata.data.active
                                              )
                                                ? _vm._i(
                                                    r.metadata.data.active,
                                                    null
                                                  ) > -1
                                                : r.metadata.data.active
                                            },
                                            on: {
                                              change: [
                                                function($event) {
                                                  var $$a =
                                                      r.metadata.data.active,
                                                    $$el = $event.target,
                                                    $$c = $$el.checked
                                                      ? true
                                                      : false
                                                  if (Array.isArray($$a)) {
                                                    var $$v = null,
                                                      $$i = _vm._i($$a, $$v)
                                                    if ($$el.checked) {
                                                      $$i < 0 &&
                                                        _vm.$set(
                                                          r.metadata.data,
                                                          "active",
                                                          $$a.concat([$$v])
                                                        )
                                                    } else {
                                                      $$i > -1 &&
                                                        _vm.$set(
                                                          r.metadata.data,
                                                          "active",
                                                          $$a
                                                            .slice(0, $$i)
                                                            .concat(
                                                              $$a.slice($$i + 1)
                                                            )
                                                        )
                                                    }
                                                  } else {
                                                    _vm.$set(
                                                      r.metadata.data,
                                                      "active",
                                                      $$c
                                                    )
                                                  }
                                                },
                                                function($event) {
                                                  return _vm.changeActive(r.id)
                                                }
                                              ]
                                            }
                                          }),
                                          _c("label", {
                                            staticClass: "custom-control-label",
                                            attrs: {
                                              for: "customSwitch" + r.id
                                            }
                                          })
                                        ]
                                      )
                                    ]),
                                    _c("td", [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "d-flex  align-items-center justify-content-center"
                                        },
                                        [
                                          _c("i", {
                                            staticClass: "fas fa-trash mr-1",
                                            staticStyle: {
                                              color: "#d0a434",
                                              cursor: "pointer"
                                            },
                                            on: {
                                              click: function($event) {
                                                return _vm.deleteTournament(
                                                  r.id
                                                )
                                              }
                                            }
                                          }),
                                          _c(
                                            "router-link",
                                            {
                                              attrs: {
                                                to: {
                                                  name: "EditTournament",
                                                  query: { id: r.id }
                                                }
                                              }
                                            },
                                            [
                                              _c("i", {
                                                staticClass:
                                                  "fas fa-pencil-alt ml-1"
                                              })
                                            ]
                                          )
                                        ],
                                        1
                                      )
                                    ])
                                  ])
                                })
                          ],
                          2
                        )
                  ]
                )
              ])
            ])
          ])
        : _vm._e()
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("tr", [
      _c(
        "td",
        { staticStyle: { "text-align": "center" }, attrs: { colspan: "7" } },
        [
          _c("div", { staticClass: "d-flex justify-content-center" }, [
            _c(
              "div",
              {
                staticClass: "spinner-border text-primary",
                attrs: { role: "status" }
              },
              [_c("span", { staticClass: "sr-only" }, [_vm._v("Loading...")])]
            )
          ])
        ]
      )
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }